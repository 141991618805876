<template>
  <div class="page-container">
    <div class="section-top">
      <div class="title-holder">
        <a href="#/Personal" class="link-back">
          <i class="icon icon-arrow-back"></i>
        </a>
        <h1 class="title">
          <span class="title-item">{{ title }}</span>
        </h1>
      </div>
    </div>
    <div class="section-main">
      <div class="form-holder">
        <form
          @submit.prevent="submitUserForm()"
          id="user-info"
          ref="form"
          method="post"
        >
          <div
            class="form-group"
            v-bind:class="{ 'form-group-error': $v.userName.$error }"
          >
            <input
              id="user_name"
              name="user_name"
              type="text"
              v-model="userName"
              @input="$v.userName.$touch()"
              @blur="$v.userName.$touch()"
              class="input"
              v-bind:class="{ 'has-value': $v.userName.$model }"
            />
            <label for="user_name" class="label">ПІБ</label>
            <span
              class="icon icon-error"
              aria-label="icon error"
              v-if="$v.userName.$error"
            ></span>
            <div class="error" v-if="$v.userName.$error">Вкажіть ПІБ</div>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'form-group-error': $v.userEmail.$error }"
          >
            <input
              id="user_email"
              name="user_email"
              type="text"
              v-model="userEmail"
              @input="$v.userEmail.$touch()"
              @blur="$v.userEmail.$touch()"
              class="input"
              v-bind:class="{ 'has-value': $v.userEmail.$model }"
            />
            <label for="user_email" class="label">E-mail</label>
            <span
              class="icon icon-error"
              aria-label="icon error"
              v-if="$v.userEmail.$error"
            ></span>
            <div class="error" v-if="$v.userEmail.$error">Вкажіть E-mail</div>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'form-group-error': $v.userPhone.$error }"
          >
            <the-mask
              mask="+38 (0##) ###-##-##"
              id="user_phone"
              name="user_phone"
              type="tel"
              v-model="userPhone"
              class="input"
              @input="$v.userPhone.$touch()"
              @blur.native="$v.userPhone.$touch()"
              v-bind:class="{ 'has-value': $v.userPhone.$model }"
            />
            <label for="user_phone" class="label">Телефон</label>
            <span
              class="icon icon-error"
              aria-label="icon error"
              v-if="$v.userPhone.$error"
            ></span>

            <div class="error" v-if="$v.userPhone.$error">
              Вкажіть номер телефону
            </div>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'form-group-error': $v.userRegion.$error }"
          >
            <v-select
              id="user_region"
              name="user_region"
              class="select"
              label="regionName"
              :options="region"
              :searchable="false"
              :clearable="false"
              :components="{ OpenIndicator }"
              @change="$v.select.$touch()"
              @blur="$v.userRegion.$touch()"
              v-model="userRegion"
              v-bind:class="{ 'has-value': $v.userRegion.$model }"
              :reduce="(regionName) => regionName.locationCode"
            ></v-select>
            <label for="user_region" class="label">Область</label>
            <div class="error" v-if="$v.userRegion.$error">
              Вкажіть вашу область
            </div>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'form-group-error': $v.userNumber.$error }"
          >
            <input
              id="user_number"
              name="user_number"
              type="text"
              v-model="userNumber"
              @input="$v.userNumber.$touch()"
              @blur="$v.userNumber.$touch()"
              class="input"
              v-bind:class="{ 'has-value': $v.userNumber.$model }"
            />
            <label for="user_number" class="label">ЄДРПОУ</label>
            <span
              class="icon icon-error"
              aria-label="icon error"
              v-if="$v.userNumber.$error"
            ></span>
            <div class="error" v-if="$v.userNumber.$error">
              Вкажіть ЄДРПОУ. Тільки цифри
            </div>
          </div>

          <div
            class="form-group"
            v-bind:class="{ 'form-group-error': $v.userCompany.$error }"
          >
            <input
              id="user_company"
              name="user_company"
              type="text"
              v-model="userCompany"
              @input="$v.userCompany.$touch()"
              @blur="$v.userCompany.$touch()"
              class="input"
              v-bind:class="{ 'has-value': $v.userCompany.$model }"
            />
            <label for="user_company" class="label">Назва підприємства</label>
            <span
              class="icon icon-error"
              aria-label="icon error"
              v-if="$v.userCompany.$error"
            ></span>
            <div class="error" v-if="$v.userCompany.$error">
              Вкажіть назву підприємства
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="section-bottom">
      <button
        type="submit"
        class="btn btn-full-width btn-main"
        form="user-info"
      >
        Зберегти
      </button>
    </div>
  </div>
</template>

<script>
import { required, email, helpers } from "vuelidate/dist/validators.min";
import { TheMask } from "vue-the-mask";
const phone = helpers.regex("phone", /^[0-9]{9}$/);
const num = helpers.regex("num", /^[0-9]{4,}$/);

export default {
  components: {
    TheMask,
  },
  data() {
    return {
      info: null,
      title: "Редагування",
      userName: null,
      userPhone: null,
      userEmail: null,
      userRegion: null,
      userNumber: null,
      userCompany: null,
      OpenIndicator: {
        render: (createElement) => createElement("span"),
      },
      region: [
        {
          regionCode: "300001",
          regionName: "Вінницька область",
        },
        {
          regionCode: "300002",
          regionName: "Волинська область",
        },
        {
          regionCode: "300003",
          regionName: "Дніпропетровська область",
        },
        {
          regionCode: "300032",
          regionName: "Донецька область",
        },
        {
          regionCode: "300005",
          regionName: "Житомирська область",
        },
        {
          regionCode: "300006",
          regionName: "Закарпатська область",
        },
        {
          regionCode: "300007",
          regionName: "Запорізька область",
        },
        {
          regionCode: "300008",
          regionName: "Івано-Франківська область",
        },
        {
          regionCode: "300009",
          regionName: "Київська область",
        },
        {
          regionCode: "300010",
          regionName: "Кіровоградська область",
        },
        {
          regionCode: "140420",
          regionName: "Луганська область",
        },
        {
          regionCode: "300012",
          regionName: "Львівська область",
        },
        {
          regionCode: "300013",
          regionName: "Миколаївська область",
        },
        {
          regionCode: "300014",
          regionName: "Одеська область",
        },
        {
          regionCode: "300015",
          regionName: "Полтавська область",
        },
        {
          regionCode: "300016",
          regionName: "Рівненська область",
        },
        {
          regionCode: "300017",
          regionName: "Сумська область",
        },
        {
          regionCode: "300018",
          regionName: "Тернопільська область",
        },
        {
          regionCode: "300019",
          regionName: "Харківська область",
        },
        {
          regionCode: "300020",
          regionName: "Херсонська область",
        },
        {
          regionCode: "300021",
          regionName: "Хмельницька область",
        },
        {
          regionCode: "300022",
          regionName: "Черкаська область",
        },
        {
          regionCode: "300024",
          regionName: "Чернігівська область",
        },
        {
          regionCode: "300023",
          regionName: "Чернівецька область",
        },
      ],
    };
  },
  validations: {
    userName: {
      required,
    },
    userPhone: {
      required,
      phone,
    },
    userEmail: {
      required,
      email,
    },
    userRegion: {
      required,
    },
    userNumber: {
      required,
      num,
    },
    userCompany: {
      required,
    },
  },
  methods: {
    async saveLocalStorage() {
      this.users = this.$resource(
        "https://nfm.com.ua/index.php?route=api/app/users/" +
          localStorage.userId
      );
      // this.users = this.$resource(
      //   "https://dev3221.nfm.com.ua/index.php?route=api/app/users/" +
      //     localStorage.userId
      // );
      await this.users
        .get()
        .then((response) => response.json())
        .then((response) => {
          this.resultUser = response;
          console.log(this.resultUser);
        });
      if (!this.resultUser.can_use_app) {
        localStorage.clear();
        return;
      }
      this.userName = this.resultUser.name;
      this.userPhone = this.resultUser.phone;
      this.userRegion = this.resultUser.region.name;
      this.userNumber = this.resultUser.egrpou;
      this.userCompany = this.resultUser.company_name;
      this.userEmail = this.resultUser.email;

      console.log(this);
      // if (localStorage.userName) {
      //   this.userName = localStorage.userName
      // }
      // if (localStorage.userEmail) {
      //   this.userEmail = localStorage.userEmail
      // }
      // if (localStorage.userPhone) {
      //   this.userPhone = localStorage.userPhone
      // }
      // if (localStorage.userRegion) {
      //   this.userRegion = localStorage.userRegion
      // }
      // if (localStorage.userNumber) {
      //   this.userNumber = localStorage.userNumber
      // }
      // if (localStorage.userCompany) {
      //   this.userCompany = localStorage.userCompany
      // }
    },
    submitUserForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.$v.$reset();
        this.submitStatus = "PENDING";

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        var urlencoded = new URLSearchParams();
        urlencoded.append("name", this.userName);
        urlencoded.append("e-mail", this.userEmail);
        urlencoded.append("phone", this.userPhone);
        urlencoded.append("region", this.userRegion);
        urlencoded.append("egrpou", this.userNumber);
        urlencoded.append("company_name", this.userCompany);
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: urlencoded,
          redirect: "follow",
        };

        fetch(
          "https://nfm.com.ua/index.php?route=api/app/users/" +
            localStorage.userId,
          requestOptions
        )
          .then((response) => response.text())
          .then((result) => console.log(result))
          .catch((error) => console.log("error", error));
      }
    },
  },
  mounted() {
    this.saveLocalStorage();
  },
};
</script>
