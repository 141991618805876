<template>
  <div class="page-container">
    <div class="section-top">
      <div class="title-holder">
        <a href="#/" class="link-back">
          <i class="icon icon-arrow-back"></i>
        </a>
        <h1 class="title">
          <span class="title-item">{{ title }}</span>
        </h1>
      </div>
    </div>
    <div class="section-main-form">
      <div class="form-holder">
        <form
          @submit.prevent="submitUserForm()"
          id="user-info"
          ref="form"
          method="post"
        >
          <p v-if="sendCodePage" class="helper-text">
            СМС з кодом відправлено на номер <span>+380{{ userPhone }}</span>
          </p>
          <div
            v-if="!sendCodePage"
            class="form-group"
            v-bind:class="{ 'form-group-error': $v.userPhone.$error }"
          >
            <the-mask
              mask="+38 (0##) ###-##-##"
              id="user_phone"
              name="user_phone"
              type="tel"
              v-model="userPhone"
              @input="$v.userPhone.$touch()"
              @blur.native="$v.userPhone.$touch()"
              class="input"
              v-bind:class="{ 'has-value': $v.userPhone.$model }"
            />
            <label for="user_email" class="label">Номер телефону</label>
            <span
              class="icon icon-error"
              aria-label="icon error"
              v-if="$v.userPhone.$error"
            ></span>
            <div class="error" v-if="$v.userPhone.$error">Вкажіть номер</div>
            <div class="error" v-if="codeErrorMessage">
              {{ codeErrorMessage }}
            </div>
          </div>
          <div
            v-else
            class="form-group"
            v-bind:class="{ 'form-group-error': $v.userCode.$error }"
          >
            <otp-input
              :isValid="isCodeValid"
              :class="[isCodeValid ? '' : 'error']"
              :digits="4"
              placeholder=" "
              @on-complete="onCompleteHandler"
              @on-changed="onChangedHandler"
              @on-paste="onPasteHandler"
            >
            </otp-input>
            <div class="error" v-if="codeErrorMessage">
              {{ codeErrorMessage }}
            </div>
          </div>
          <div class="login-buttons">
            <button
              @click="emailLoginForm()"
              type="button"
              class="link-red link-center email-link"
              form="user-info"
            >
              Вхід через E-mail
            </button>
            <div class="button-row">
              <button
                type="submit"
                class="btn btn-main btn-full-width"
                form="user-info"
              >
                {{ sendCodePage ? "Увійти" : "Отримати код" }}
              </button>
            </div>
            <div class="button-row">
              <button
                @click="registrationForm()"
                type="button"
                class="btn-border btn-full-width"
                form="user-info"
              >
                Зареєструватися
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <modal v-show="isModalVisible" @close="closeModal" />
  </div>
</template>

<style>
.login-buttons {
}
.vue-otp-input {
  width: 100%;
  margin: 0 auto;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  margin-top: 5px;
  text-align: left;
}

.vue-otp-input.error {
  position: relative !important;
  bottom: 0px !important;
}
.btn-full-width {
  cursor: pointer;
}
.helper-text {
  margin-bottom: 30px;
}
.helper-text span {
  font-weight: 700;
}
.forgot-button {
  margin: 0 0 20px;
}
.btn-border {
  border: 1px solid #c70629;
  padding: 16px 10px;
  background: #fff;
  color: #000;
  display: block;
  font-weight: bold;
}
.link-center {
  text-align: center;
  display: block;
  background: transparent;
  border: none;
  margin-top: 10px;
}

.email-link {
  margin-bottom: 20px;
}

.section-main-form {
  /*padding: 50% 0 0px;*/
  padding: 55px 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
}
.button-row {
  margin: 0 0 15px;
}
.error-message {
  margin: -20px 0 35px;
  font-size: 13px;
  line-height: 16px;
  color: #c70629;
}
.form-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.form-group label {
  left: 15px;
}
.form-group .error {
  position: relative;
}
</style>

<script>
import { required, helpers } from "vuelidate/dist/validators.min";
import modal from "../modal/ModalErrorConnecting";
import OtpInput from "otp-input-vue2";
import { TheMask } from "vue-the-mask";
const phone = helpers.regex("phone", /^[0-9]{9}$/);

export default {
  data() {
    return {
      title: "Вхід",
      userPhone: null,
      userCode: null,
      currentUser: false,
      userPassword: null,
      isCodeValid: true,
      result: "",
      userToken: null,
      isModalVisible: false,
      isUserValid: false,
      usersList: null,
      userId: null,
      isNoUser: false,
      submitStatus: null,
      pass: false,
      sendCodePage: false,
      codeErrorMessage: null,
      devSendCode: null,
    };
  },
  components: {
    modal,
    OtpInput,
    TheMask,
  },
  validations: {
    userPhone: {
      required,
      phone,
    },
    userCode: {
      maxLength: 4,
    },
  },
  methods: {
    saveLocalStorage() {
      if (localStorage.userPhone) {
        this.userPhone =
          localStorage.userPhone === "undefined"
            ? null
            : localStorage.userPhone;
      }
    },
    onCompleteHandler(code) {
      console.log({ code }, "code");
      this.userCode = code;
    },
    onChangedHandler(lastEnteredCode) {
      if (lastEnteredCode.length === 0) {
        this.isCodeValid = true;
      }
    },
    onPasteHandler(code) {},
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    async submitUserForm() {
      const user = {
        phone: `+380${localStorage.getItem("userPhone")}`,
      };
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.$v.$reset();
        this.submitStatus = "PENDING";

        window.profile = {
          userPhone: this.userPhone,
          userPassword: this.userPassword,
        };
        if (!this.sendCodePage) {
          await this.$http
            .post(
              "https://nfm.com.ua/index.php?route=api/app/loginReceiveCode",
              user,
              {
                emulateJSON: true,
              }
            )
            .then((response) => {
              this.result = response;
            })
            .catch((response) => {
              this.result = response;
            });
          if (this.result.status === 200) {
            if (this.result.body.status === "ok") {
              this.devSendCode = this.result.body.code;
              this.sendCodePage = true;
              this.codeErrorMessage = "";
            } else if (this.result.body.status === "error") {
              this.codeErrorMessage = this.result.body.error_phone;
            }
          } else {
            this.showModal();
          }
        } else {
          const codeUser = {
            phone: `+380${localStorage.getItem("userPhone")}`,
            phone_code: this.userCode,
          };
          await this.$http
            .post(
              "https://nfm.com.ua/index.php?route=api/app/loginSendCode",
              codeUser,
              {
                emulateJSON: true,
              }
            )
            .then((response) => {
              this.result = response;
            })
            .catch((response) => {
              this.result = response;
            });
          if (this.result.status === 200) {
            if (this.result.body.status === "ok") {
              this.codeErrorMessage = null;
              localStorage.setItem("userId", this.result.body.id);
              this.userId = this.result.body.id;
              if (this.userId === null) {
                this.isNoUser = true;
              } else {
                this.isNoUser = false;
                this.isUserValid = false;
                this.userToken = this.result.body.token;
                localStorage.setItem("userToken", this.userToken);
                this.$router.push("/");
                this.submitStatus = "OK";
                document.cookie = "userToken=" + this.userToken;
                document.cookie = "userId=" + this.userId;
              }
            } else {
              this.codeErrorMessage = this.result.body.error_phone_code;
              this.isCodeValid = false;
              this.isUserValid = true;
            }
          } else {
            console.log(this.result, "this.result1");
            this.showModal();
          }
        }
      }
    },
    async getUserId() {
      let emailUser = this.userPhone;
      let userIdLocal = null;
      await this.$http
        .get("https://nfm.com.ua/index.php?route=api/app/users")
        .then((response) => response.json())
        .then((response) => {
          this.usersList = response;
          this.usersList.forEach(function (item, i, arr) {
            if (item.email === emailUser) {
              userIdLocal = item.id;
              return userIdLocal;
            } else {
              return false;
            }
          });
          this.userId = userIdLocal;
          localStorage.setItem("userId", this.userId);
        });
    },
    registrationForm() {
      this.$router.push("/Registration");
    },
    emailLoginForm() {
      this.$router.push("/EmailLogin");
    },
    showPassword() {
      this.pass = !this.pass;
    },
  },
  mounted() {
    this.saveLocalStorage();
  },
  watch: {
    userPhone(phone) {
      localStorage.setItem("userPhone", phone || "");
    },
    userPassword(userPassword) {
      localStorage.userPassword = userPassword;
    },
  },
};
</script>
